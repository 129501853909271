import React from "react";
import { Link, graphql, navigate } from "gatsby";

import styled from "styled-components";
import Questions from "../pages/questions";
import HelpCenterLayout from "../components/HelpCenter/HelpCenterLayout";
import Post from "../components/HelpCenter/Post";
import Topics from "../components/HelpCenter/Topics";
import { takepath } from "../pages/questions";
class Help extends React.Component {
  state = {
    topics: [],
    selected: "",
    mode: "menu", // menu | post
    pathX: ""
  };

  componentDidMount() {
    const { data } = this.props;
    const topics = data.allMarkdownRemark.edges;
    this.setState({ topics, defaultTopics: topics });
  }

  handleSubmit = event => {
    const { searchTerm, defaultTopics } = this.state;

    const filteredTopics = this.filterTopics(searchTerm, defaultTopics);

    this.setState({ topics: filteredTopics });

    event.preventDefault();
  };

  filterTopics = (searchTerm, topics) =>
    topics.filter(
      topic => topic.node.frontmatter.title.indexOf(searchTerm) > -1
    );

  handleTopicSelect = (html, title, path) => {
    this.setState({ pathX: path });
    const numberOfSlashes = path.replace(/[^\/]/g, "").length;
    if (numberOfSlashes == 2) {
      navigate(`/help?category=${path.split("/").pop()}`);
    } else {
      navigate(path);
    }
  };

  handleBack = () => this.setState({ mode: "menu" });

  render() {
    const { topics, selected, mode, pathX } = this.state;
    const { data, location } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const numberOfSemicolons = location.href
      ? location.href.replace(/[^\?]/g, "").length
      : null;
    const url = numberOfSemicolons ? location.href.split("?").pop() : "";
    let realpath = url;
    let content = null;
    switch (mode) {
      case "menu":
        content = (
          <Topics
            onSelect={this.handleTopicSelect}
            topics={topics}
            pathX={realpath}
            location={location}
          />
        );
        break;
      case "post":
        content = (
          <Post
            handleBack={this.handleBack}
            html={selected.html}
            title={selected.title}
            subtitle={selected.subtitle}
            subject={selected.subject}
            displayTitle={selected.displayTitle}
          />
        );
        break;
      default:
        content = null;
    }
    return (
      <HelpCenterLayout
        handleSubmit={this.handleSubmit}
        location={location}
        siteTitle={siteTitle}
      >
        {content}
      </HelpCenterLayout>
    );
  }
}

export default Help;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            subtitle
            subject
            displayTitle
          }
        }
      }
    }
  }
`;
